div.gallery
{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

div.gallery_image
{
    display: flex;
    align-items: center;
    max-width: var(--gallery_image_size);
    max-height: var(max(--gallery_image_size, 100%));
    height: fit-content;
    margin: 0.75rem;
}

div.gallery_image div.expandable_image
{
    padding: 0;
}

div.gallery_image div.expandable_image img
{
    max-height: 100%;
    box-shadow: 0.2rem 0.2rem 0.2rem var(--gallery_image_shadow_color);
}
