div.expandable_image
{
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    max-height: 100%;
}

div.expandable_image img
{
    object-fit: contain;
    width: min-content;
    max-width: 100%;
    cursor: pointer;
}
