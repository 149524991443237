:root
{
    --primary_color: #000000;
    --primary_color_dark: #000000;
    --body_background_color: #d6ccc2;
    --content_background_color: #eeded1;
    /* --footer_background_color: #d6ccc2; */
    --footer_background_color: var(--content_background_color);
    --main_text_color: #000000;
    --link_color: #2b5e75;
    --link_color_active: #1ea0dc;
    --link_color_inactive: #26719f;
    --heading_color: #0a0202;
    /* --heading_color: var(--primary_color); */
    --hero_text_color: #eee8e8;
    --header_font: 'Josefin Sans', 'Open Sans', sans-serif;
    /* --header_font: 'Josefin Sans', 'Open Sans', sans-serif; */
    --main_menu_font: 'Josefin Sans', 'Open Sans', sans-serif;
    --main_text_font: 'Open Sans', sans-serif;
    --heading_font: 'Josefin Sans', 'Open Sans', sans-serif;
    /* --heading_font: 'Josefin Sans', 'Open Sans', sans-serif; */
    --darkbox_background_color: rgba(0, 0, 0, 0.5);
    --darkbox_panel_min_size: 4rem;
    --gallery_image_size: 8rem;
    --gallery_image_shadow_color: rgba(8, 8, 8, 0.25);
}

@font-face
{
    font-family: 'Josefin Sans';
    src: url(fonts/JosefinSans-VariableFont_wght.ttf);
}

@font-face
{
    font-family: 'Open Sans';
    src: url(fonts/OpenSans-VariableFont_wdth,wght.ttf);
}

body
{
    background-color: var(--body_background_color);
    color: var(--main_text_color);
    font-family: var(--main_text_font);
}

.App
{
  text-align: left;
}

header.hero
{
    display: table;
    width: 100%;
    text-align: center;
    font-family: var(--header_font);

    height: calc(1vh * 40);
    min-height: 20rem;

    background-image: url('./images/layout_elements/hero_image.jpg');
    background-attachment: fixed;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

header.hero div.center_vertically
{
    display: table-cell;
    vertical-align: middle;
}

header.hero h1
{
    font-size: 6rem;
    line-height: 6rem;
    font-weight: 300;
    margin: 4rem 0 0 0;
    color: var(--hero_text_color);
	text-shadow: 0.25rem 0.25rem 0.25rem #00000080, -0.125rem -0.125rem 0.125rem #00000080;

}

header.hero h2
{
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: 400;
    margin: 0.5rem 0 2rem 0;
    color: var(--hero_text_color);
    text-shadow: 0.2rem 0.2rem 0.2rem #00000080, -0.1rem -0.1rem 0.1rem #00000080;
}

div.content
{
    border-radius: 1rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5), 2px 2px 5px rgba(0, 0, 0, 0.5);
    margin: 0rem 6% 1rem 6%;
    padding: 1rem 2%;
    background-color: var(--content_background_color);
}

div.content h3
{
    text-align: center;
    font-size: 1.75rem;
    font-weight: 400;
    font-family: var(--heading_font);
    color: var(--heading_color);
    margin: 1rem;
}

div.content p
{
    text-align: justify;
}

div.content p.disclaimer, div.footer p.disclaimer
{
    font-weight: 600;
    margin-top: 1rem;
}

div.center_content, div.center_content p
{
    text-align: center;
}

div.content h4
{
    text-align: center;
    font-size: 1.25rem;
    font-weight: 300;
    font-family: var(--heading_font);
    color: var(--heading_color);
    margin: 0.5rem;
}

div.content h4.link_heading a
{
    text-decoration: none;
    color: var(--link_color_inactive);
}

div.content h4.link_heading a:hover
{
    text-decoration: underline;
    color: var(--link_color_active);
}

ul
{
    list-style: none;
    margin-bottom: 2.5rem;
    padding-left: 2rem;
}

ul li::before
{
    content: "\2022";
    margin-right: 0.5rem;
    color: var(--primary_color);
}
