div.darkbox
{
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: var(--darkbox_background_color);
}

div.darkbox_inner
{
    display: flex;
    flex-direction: column;
    height: 100%;
}

div.arrange_horizontal
{
    display: flex;
    flex-direction: row;
    align-items: center;
}

div.darkbox_stretched div.arrange_horizontal
{
    width: 100%;
    height: 100%;
}

div.top_panel, div.bottom_panel, div.left_panel, div.right_panel
{
    cursor: pointer;
}

div.top_panel:hover, div.bottom_panel:hover, div.left_panel:hover, div.right_panel:hover
{
    background-color: var(--darkbox_background_color);
    cursor: pointer;
}

div.top_panel, div.bottom_panel
{
    min-height: var(--darkbox_panel_min_size);
    flex-grow: 1;
}

div.left_panel, div.right_panel
{
    min-width: var(--darkbox_panel_min_size);
    flex-grow: 1;
    display: flex;
    align-items: center;
    height: 100%;
}

div.right_panel
{
    justify-content: flex-end;
}

div.darkbox_stretched div.darkbox_content
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

div.darkbox_content img.loading_image
{
    width: var(--darkbox_panel_min_size);
    height: var(--darkbox_panel_min_size);
}

div.darkbox_content
{
    display: flex;
    width: fit-content;
    height: fit-content;
}

div.darkbox_content img.content_image
{
    max-width: calc(100vw - 2 * var(--darkbox_panel_min_size));
    max-height: calc(100vh - 2 * var(--darkbox_panel_min_size));
}

img.darkbox_button
{
    width: var(--darkbox_panel_min_size);
    height: var(--darkbox_panel_min_size);
}
