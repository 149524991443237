div.contact_page_content h1
{
    font-family: var(--header_font);
    font-size: 6rem;
    line-height: 6rem;
    font-weight: 200;
    margin: 2rem 0 0 0;
    color: var(--primary_color);
	text-shadow: 0.25rem 0.25rem 0.25rem #00000080, -0.125rem -0.125rem 0.125rem #00000080;
}

div.contact_page_content h2
{
    font-family: var(--header_font);
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: 400;
    margin: 0.5rem 0 2rem 0;
    color: var(--primary_color);
    text-shadow: 0.2rem 0.2rem 0.2rem #00000080, -0.1rem -0.1rem 0.1rem #00000080;
}

div.contact_page_content span
{
    color: var(--primary_color)
}
