.map_container
{
	position: relative;
	margin: 2rem;
	padding-top: 56.25%;	/* top 100% * 16 / 9 */
    box-shadow: 0.2rem 0.2rem 0.2rem var(--gallery_image_shadow_color);
}

.map_frame
{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
