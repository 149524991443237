div.footer
{
    background-color: var(--footer_background_color);
    text-align: center;
    border-radius: 1rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5), 2px 2px 5px rgba(0, 0, 0, 0.5);
    margin: 0rem 6% 1rem 6%;
    padding: 1rem 2%;
}

div.footer h4
{
    font-family: var(--header_font);
    font-weight: 200;
    font-size: 3rem;
    line-height: 3rem;
    margin: 2rem 0;
    color: var(--heading_color);
}

div.footer h5
{
    font-family: var(--heading_font);
    font-weight: 400;
    font-size: 2rem;
    line-height: 2rem;
    margin: 2rem 0 1rem 0;
    color: var(--heading_color);
}

div.footer h6
{
    font-family: var(--heading_font);
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: 2rem 0 1rem 0;
    color: var(--heading_color);
}

div.footer p
{
    line-height: 1rem;
    margin: 1rem;
}
